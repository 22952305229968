import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { RouterProvider, useRoute } from "react-router5";
import TopMenu from "./components/main-app/topmenu";
import { InitAppRouter, AppRoutes } from "./app-router";
import { Router } from "router5";
import Home from "./components/main-app/home";
import Members from "./components/main-app/members";
import Admin from "./components/main-app/admin";
import { UserData } from "./types";
import Loading from "./components/loading";
import ActivateUser from "./components/activation/activate-user";
import { Transition } from "semantic-ui-react";
import App from "./components/app";

const AppRouter = InitAppRouter();


ReactDOM.render(
  <RouterProvider router={AppRouter}>
    <App />
  </RouterProvider>,
  document.getElementById("root")
);
