import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { RouterProvider, useRoute } from "react-router5";
import TopMenu from "./main-app/topmenu";
import { InitAppRouter, AppRoutes } from "../app-router";
import { Router } from "router5";
import Home from "./main-app/home";
import Members from "./main-app/members";
import Admin from "./main-app/admin";
import { UserData } from "../types";
import Loading from "./loading";
import ActivateUser from "./activation/activate-user";
import { Transition } from "semantic-ui-react";
import Activation from "./activation";
import MainApp from "./main-app";
import { logoutRedirectURL } from "../constants";

export default function App() {
  let route = useRoute().route;

  //if we are in activation realm, redirect to that route.
  if (route.path.startsWith("/dash/activation")) {
    return <Activation />;
  }

  return <MainApp />;
}