import React from "react";
import { Segment, Grid, Divider, Header, Icon, Search, Button, Container } from "semantic-ui-react";

export default function Welcome() {
  return (
    <div>
      <Container>
          <Header as="h2" textAlign="center">
              <br/>
              Welcome to TRI!
              <br/>
              <br/>
          </Header>
      </Container>
      <Segment placeholder>
        <Grid columns={2} stackable textAlign="center">
          <Divider vertical>Or</Divider>
          <Grid.Row verticalAlign="middle">
            <Grid.Column>
              <Header icon>
                <Icon name="user circle" />
                Visit the Members' Area
              </Header>

              <Button as="a" href="https://mytrinetwork.com" target="_blank" rel="noopener noreferrer" primary>mytrinetwork.com</Button>
            </Grid.Column>

            <Grid.Column>
              <Header icon>
                <Icon name="world" />
                Log on to your matrix account
              </Header>
              <Button as="a" href="https://mytrinetwork.org" target="_blank" rel="noopener noreferrer" primary>mytrinetwork.org</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
}
