import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ActivateUser from "./activate-user";
import { ActivationData } from "../../types";
import { useRoute, useRouter } from "react-router5";
import Loading from "../loading";
import { logoutRedirectURL, serverRootURL } from "../../constants";
import { Modal } from "semantic-ui-react";
import ErrorMessage from "../error";

export default function Activation() {
  function updateStage(n: number, body?: string) {
    //fetch and get previous dues and stripe session
    if (n == 2 && activationData.previousDues > 0) {
      fetch(serverRootURL + "/activate/stage/2", {
        method: "POST",
        headers: { Authorization: bearerToken,  "Content-Type": "text/plain" },
        body: body,
      }).then((res) => {
        if (res.status != 200) {
          setErr("HTTP status " + res.status);
          return;
        }
        res
          .json()
          .then((json) => {
            if (!json) {
              setErr("Failed to fetch JSON payments data from server");
            }
            setActivationData({
              ...activationData,
              activationStage: 2,
              previousDues: json.previousDues,
              stripeSession: json.stripeSession,
            });
          })
          .catch((err) => {
            setErr("Something unexpected happened. Maybe check your internet connection?");
            console.error(err);
          });
      });
      return;
    }

    //fetch and set country
    if (n == 2&&activationData.previousDues==0) {
      fetch(serverRootURL + "/activate/stage/" + n, {
        method: "POST",
        headers: { Authorization: bearerToken,   "Content-Type": "text/plain",},
        body: body,
      }).then((res) => {
        if (res.status != 200) {
          setErr("Status Code: " + res.status);
          return;
        }
        getActivationData();
      });
      return;
    }

    //fetch and check if status is 200.
    fetch(serverRootURL + "/activate/stage/" + n, {
      method: "POST",
      headers: { Authorization: bearerToken },
    }).then((res) => {
      if (res.status != 200) {
        setErr("Status Code: " + res.status);
        return;
      }
      getActivationData();
    });
    return;
  }

  function getActivationData(token?: string): Promise<ActivationData> {
    return new Promise((res, rej) => {
      let newToken;
      if (!bearerToken) {
        let activationToken = token || localStorage.getItem("activationToken");
        if (!activationToken) {
          setErr("Unauthorized");
          window.location.replace(logoutRedirectURL);
          return;
        }
        localStorage.setItem("activationToken", activationToken);
        newToken = "Bearer " + activationToken;
        setBearerToken(newToken);
      } else {
        newToken = bearerToken;
      }

      return fetch(serverRootURL + "/activate/", {
        method: "POST",
        headers: {
          Authorization: newToken,
        },
      })
        .then((response) => {
          if (response.status != 200) {
            window.location.replace(logoutRedirectURL);
            return;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          setActivationData(data);
          res(data);
        });
    });
  }

  const initialActivationData: ActivationData = {
    name: "",
    email: "",
    activated: false,
    activationStage: 0,
    country: "",
    paymentPlan: "",
    accountStanding: false,
    previousDues: 0,
  };

  const [activationData, setActivationData] = useState(initialActivationData);
  const [loading, setLoading] = useState(true);
  const [bearerToken, setBearerToken] = useState("");
  const [err, setErr] = useState("");
  let queryToken = useRouter().getState().params.token;

  useEffect(() => {
    getActivationData(queryToken).then((data) => {
      console.log(data);
      if (!data || data.activated) {
        window.location.replace(logoutRedirectURL);
      }
      setLoading(false);
    });
  }, []);

  if (err) {
    return <ErrorMessage message={err} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <ActivateUser
        activationData={activationData}
        updateStage={(n: number) => {
          updateStage(n);
        }}
      />
    </div>
  );
}
