import React from 'react';
import ReactDOM from 'react-dom';
import { Header, Icon, Placeholder, Grid, Divider } from 'semantic-ui-react';
import { UserData } from '../../types';

export default function Home(props: {"userData":UserData}) {
    return (<div>
        <Header as='h2' icon textAlign='center'>
            <Icon name='user' circular />
            <Header.Content>Welcome, user!</Header.Content>
            <Divider hidden></Divider>
            <Grid columns={3} stackable>
                <Grid.Column></Grid.Column>
                <Grid.Column>
                <Placeholder>
                    <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Paragraph>
                    <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Paragraph>
                </Placeholder>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid>
        </Header>
    </div>)
}