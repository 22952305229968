import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'semantic-ui-react';


export default function ErrorMessage({message}: {message: string}){
    return (
        <Modal open>
        <Modal.Header>Oops!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Something bad happened, please try again later, or contact us at
              hello@mytrinetwork.com
            </p>
            <p> Error details: {message}</p>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
}