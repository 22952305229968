import React, { useState } from "react";
import {
  List,
  Button,
  Container,
  Header,
  Divider,
  Icon,
  Modal,
} from "semantic-ui-react";

export default function Terms({
  updateStage,
}: {
  updateStage: (stage: number) => void;
}) {
  let [termsOpen, setTermsOpen] = useState(false);
  let [privacyOpen, setPrivacyOpen] = useState(false);

  return (
    <div>
      <Header as="h2" icon textAlign="center">
        <Header.Content className="terms-header">
          Terms and Privacy Policy
        </Header.Content>
      </Header>
      <Container>
        <div>
          <p>To continue, please review our terms of use and privacy policy.</p>
          <br />
          <div>
            <List divided relaxed>
              <List.Item>
                <List.Icon
                  name="checkmark"
                  size="large"
                  verticalAlign="middle"
                  color="green"
                />
                <List.Content>
                  <Modal
                    closeIcon
                    trigger={
                      <List.Header
                        as="a"
                        onClick={(e: Event) => {
                          e.preventDefault();
                          setPrivacyOpen(true);
                        }}
                      >
                        Privacy Policy
                      </List.Header>
                    }
                    open={privacyOpen}
                    onClose={() => {
                      setPrivacyOpen(false);
                    }}
                  >
                    <Modal.Header>Privacy Policy</Modal.Header>
                    <Modal.Content scrolling>
                      <PrivacyActual />
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        primary
                        onClick={() => {
                          setPrivacyOpen(false);
                        }}
                      >
                        Proceed <Icon name="angle right" />
                      </Button>
                    </Modal.Actions>
                  </Modal>
                  <List.Description as="a">
                    Last updated on 28th May 2020
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon
                  name="checkmark"
                  size="large"
                  verticalAlign="middle"
                  color="green"
                />
                <List.Content>
                  <Modal
                    closeIcon
                    trigger={
                      <List.Header
                        as="a"
                        onClick={(e: Event) => {
                          e.preventDefault();
                          setTermsOpen(true);
                        }}
                      >
                        Terms of Use
                      </List.Header>
                    }
                    open={termsOpen}
                    onClose={() => {
                      setTermsOpen(false);
                    }}
                  >
                    <Modal.Header>Terms of Service</Modal.Header>
                    <Modal.Content scrolling>
                      <TermsActual />
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        primary
                        onClick={() => {
                          setTermsOpen(false);
                        }}
                      >
                        Proceed <Icon name="angle right" />
                      </Button>
                    </Modal.Actions>
                  </Modal>
                  <List.Description as="a">
                    Last updated on 29th June 2020
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </div>
          <Divider />
          <p>
            <br />
            By clicking on "I agree", you agree to have reviewed and agree to
            the above, and consent to the use of cookies and other technologies
            simliar to cookies to identify you as you use our website.
            <br />
            <br />
          </p>
          <br />

          <Button
            icon
            labelPosition="left"
            fluid
            onClick={() => {
              updateStage(1);
            }}
            size="large"
            primary
          >
            I Agree
            <Icon name="checkmark"></Icon>
          </Button>
        </div>
      </Container>
    </div>
  );
}

const TermsActual = function () {
  return (
    <div>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Terms of Service</title>
      {/* #######  THIS IS A COMMENT - Visible only in the source editor #########*/}
      <p className="p1">
        <span className="s1">
          <strong>Terms of Service</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          <em>Last updated: June 29, 2020</em>
        </span>
      </p>
      <p className="p3">&nbsp;</p>
      <p className="p2">
        <span className="s1">
          When we say “Company”, “we”, “our”, or “us” in this document, we are
          referring to TRI.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          When we say “Services”, we mean any product created and maintained by
          TRI. That includes TRI whether delivered within a web browser, desktop
          application, mobile application, or another format.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          We may update these Terms of Service in the future. Typically these
          changes have been to clarify some of these terms by linking to an
          expanded related policy.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          When you use our Services, now or in the future, you are agreeing to
          the latest Terms of Service. That's true for any of our existing and
          future products and all features that we add to our Services over
          time. There may be times where we do not exercise or enforce any right
          or provision of the Terms of Service; in doing so, we are not waiving
          that right or provision.{" "}
          <strong>These terms do contain a limitation of our liability.</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          If you violate of any of the terms, we may terminate your account.
          That's a broad statement and it means you need to place a lot of trust
          in us.
        </span>
      </p>
      <p className="p4">
        <span className="s1">
          <strong>Account Terms</strong>
        </span>
      </p>
      <ol className="ol1">
        <li className="li5">
          <span className="s2">
            You are responsible for maintaining the security of your account and
            password. The Company cannot and will not be liable for any loss or
            damage from your failure to comply with this security obligation.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            You may not use the Services for any purpose not meant for our
            service.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            You are responsible for all content posted and activity that occurs
            under your account. That includes content posted by others who
            either: (a) have access to your login credentials; or (b) have their
            own logins under your account.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            You must be a human. Accounts registered by “bots” or other
            automated methods are not permitted.
          </span>
        </li>
      </ol>
      <p className="p6">&nbsp;</p>
      <p className="p4">
        <span className="s1">
          <strong>Payment, Refunds, and Plan Changes</strong>
        </span>
      </p>
      <ol className="ol1">
        <li className="li5">
          <span className="s2">
            If you are using a free version of one of our Services, it is really
            free: we do not ask you for your credit card and — just like for
            customers who pay for our Services — we do not sell your data.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            For paid Services that offer a free trial, we explain the length of
            trial when you sign up. After the trial period, you need to pay in
            advance to keep using the Service. If you do not pay, we will freeze
            your account and it will be inaccessible until you make payment. If
            your account has been frozen for a while, we will queue it up for
            auto-cancellation.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            If you are upgrading from a free plan to a paid plan, we will charge
            your card immediately and your billing cycle starts on the day of
            upgrade. For other upgrades or downgrades in plan level, the new
            rate starts from the next billing cycle.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            All fees are exclusive of all taxes, levies, or duties imposed by
            taxing authorities. You are responsible for payment of all taxes,
            levies, or duties.
          </span>
        </li>
      </ol>
      <p className="p6">&nbsp;</p>
      <p className="p4">
        <span className="s1">
          <strong>Cancellation and Termination</strong>
        </span>
      </p>
      <ol className="ol1">
        <li className="li5">
          <span className="s2">
            You are solely responsible for properly canceling your account.{" "}
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            All of your content will be inaccessible from the Services
            immediately upon cancellation.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            We have the right to suspend or terminate your account and refuse
            any and all current or future use of our Services for any reason at
            any time.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            Verbal, physical, written or other abuse (including threats of abuse
            or retribution) of Company employee or officer will result in
            immediate account termination.
          </span>
        </li>
      </ol>
      <p className="p6">&nbsp;</p>
      <p className="p4">
        <span className="s1">
          <strong>Modifications to the Service and Prices</strong>
        </span>
      </p>
      <ol className="ol1">
        <li className="li5">
          <span className="s2">
            We make a promise to our customers to support our Services. We
            reserve the right at any time to modify or discontinue, temporarily
            or permanently, any part of our Services with or without notice.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            Sometimes we change the pricing structure for our products. When we
            do that, we tend to exempt existing customers from those changes.
            However, we may choose to change the prices for existing customers.
            If we do so, we will give at least 30 days notice and will notify
            you via the email address on record. We may also post a notice about
            changes on our websites or the affected Services themselves.
          </span>
        </li>
      </ol>
      <p className="p6">&nbsp;</p>
      <p className="p4">
        <span className="s1">
          <strong>Uptime, Security, and Privacy</strong>
        </span>
      </p>
      <ol className="ol1">
        <li className="li5">
          <span className="s2">
            Your use of the Services is at your sole risk. We provide these
            Services on an “as is” and “as available” basis.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            We reserve the right to temporarily disable your account if your
            usage significantly exceeds the average usage of other customers of
            the Services. Of course, we'll reach out to the account owner before
            taking any action except in rare cases where the level of use may
            negatively impact the performance of the Service for other
            customers.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            We take many measures to protect and secure your data through
            backups, redundancies, and encryption. We enforce encryption for
            data transmission from the public Internet. There are some edge
            cases where we may send your data through our network unencrypted.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            When you use our Services, you entrust us with your data. We as
            humans can access your data for the following reasons:
          </span>
        </li>
      </ol>
      <ul className="ul1">
        <li className="li5">
          <span className="s2">
            To help you with support requests you make. We'll ask for express
            consent before accessing your account.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            To safeguard TRI. We'll look at logs and metadata as part of our
            work to ensure the security of your data and the Services as a
            whole. If necessary, we may also access accounts as part of an abuse
            report investigation.
          </span>
        </li>
        <li className="li5">
          <span className="s2">For maintenance</span>
        </li>
        <li className="li5">
          <span className="s2">To the extent required by applicable law.</span>
        </li>
      </ul>
      <ol className="ol1">
        <li className="li5">
          <span className="s2">
            We use third party vendors and hosting partners to provide the
            necessary hardware, software, networking, storage, and related
            technology required to run the Services.{" "}
          </span>
        </li>
      </ol>
      <p className="p3">&nbsp;</p>
      <p className="p6">&nbsp;</p>
      <p className="p4">
        <span className="s1">
          <strong>Copyright and Content Ownership</strong>
        </span>
      </p>
      <ol className="ol1">
        <li className="li5">
          <span className="s2">
            All content posted on the Services must comply with U.S. copyright
            law.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            We claim no intellectual property rights over the material you
            provide to the Services. All materials uploaded remain yours.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            We do not pre-screen content, but reserve the right (but not the
            obligation) in our sole discretion to refuse or remove any content
            that is available via the Service.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            The names, look, and feel of the Services are copyright© to the
            Company. All rights reserved. You may not duplicate, copy, or reuse
            any portion of the HTML, CSS, JavaScript, or visual design elements
            without express written permission from the Company. You must
            request permission to use the Company's logo or any Service logos
            for promotional purposes. Please email us requests to use logos. We
            reserve the right to rescind this permission if you violate these
            Terms of Service.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            any portion of the Services, use of the Services, or access to the
            Services without the express written permission by the Company.
          </span>
        </li>
        <li className="li5">
          <span className="s2">
            You must not modify another website so as to falsely imply that it
            is associated with the Services or the Company.
          </span>
        </li>
      </ol>
      <p className="p6">&nbsp;</p>
      <p className="p4">
        <span className="s1">
          <strong>Features and Bugs</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          We design our Services with care, based on our own experience and the
          experiences of customers who share their time and feedback. However,
          there is no such thing as a service that pleases everybody. We make no
          guarantees that our Services will meet your specific requirements or
          expectations.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          We also test all of our features extensively before shipping them. As
          with any software, our Services inevitably have some bugs. We track
          the bugs reported to us and work through priority ones, especially any
          related to security or privacy. Not all reported bugs will get fixed
          and we don't guarantee completely error-free Services.
        </span>
      </p>
      <p className="p6">&nbsp;</p>
      <p className="p3">&nbsp;</p>
      <p className="p6">&nbsp;</p>
      <p className="p4">
        <span className="s1">
          <strong>Liability</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          We mention liability throughout these Terms but to put it all in one
          section:
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          <strong>
            <em>
              You expressly understand and agree that the Company shall not be
              liable, in law or in equity, to you or to any third party for any
              direct, indirect, incidental, lost profits, special,
              consequential, punitive or exemplary damages, including, but not
              limited to, damages for loss of profits, goodwill, use, data or
              other intangible losses (even if the Company has been advised of
              the possibility of such damages), resulting from: (i) the use or
              the inability to use the Services; (ii) the cost of procurement of
              substitute goods and services resulting from any goods, data,
              information or services purchased or obtained or messages received
              or transactions entered into through or from the Services; (iii)
              unauthorized access to or alteration of your transmissions or
              data; (iv) statements or conduct of any third party on the
              service; (v) or any other matter relating to this Terms of Service
              or the Services, whether as a breach of contract, tort (including
              negligence whether active or passive), or any other theory of
              liability.
            </em>
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          In other words: choosing to use our Services does mean you are making
          a bet on us. If the bet does not work out, that's on you, not us. If
          you choose to use our Services, thank you for betting on us.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          If you have a question about any of the Terms of Service, please
          contact our support team.
        </span>
      </p>
      <div className="flex-shrink-0 col-12 col-md-9 mb-4 mb-md-0">
        <div
          id="readme"
          className="Box md js-code-block-container Box--responsive"
        >
          <div className="Box-body px-5 pb-5">
            <article className="markdown-body entry-content container-lg">
              <blockquote>
                <p>
                  TRI policies are open source, licensed under&nbsp;
                  <a
                    href="https://creativecommons.org/licenses/by/4.0/"
                    rel="nofollow"
                  >
                    CC BY 4.0
                  </a>
                  . Adapted from the&nbsp;
                  <a href="https://github.com/basecamp/policies">
                    Basecamp open-source policies
                  </a>
                  &nbsp;/&nbsp;
                  <a
                    href="https://creativecommons.org/licenses/by/4.0/"
                    rel="nofollow"
                  >
                    CC BY 4.0
                  </a>
                  .
                </p>
              </blockquote>
            </article>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 col-12 col-md-3">&nbsp;</div>
    </div>
  );
};

function PrivacyActual() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>Last updated: May 28, 2020</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <h1>Interpretation and Definitions</h1>
      <h2>Interpretation</h2>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h2>Definitions</h2>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <p>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </p>
        </li>
        <li>
          <p>
            <strong>Company</strong> (referred to as either \"the Company\",
            \"We\", \"Us\" or \"Our\" in this Agreement) refers to CIRCES
            International Inc., 16801 E Hialeah Ave., Centennial CO 80015.
          </p>
        </li>
        <li>
          <p>
            <strong>Application</strong> means the software program provided by
            the Company downloaded by You on any electronic device, named TRI
            App
          </p>
        </li>
        <li>
          <p>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            \"control\" means ownership of 50% or more of the shares, equity
            interest or other securities entitled to vote for election of
            directors or other managing authority.
          </p>
        </li>
        <li>
          <p>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </p>
        </li>
        <li>
          <p>
            <strong>Website</strong> refers to Templar Research Institute,
            accessible from{" "}
            <a
              href="https://templarresearch.institute"
              rel="external nofollow noopener"
              target="_blank"
            >
              Templarresearch.institute
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Service</strong> refers to the Application or the Website or
            both.
          </p>
        </li>
        <li>
          <p>
            <strong>Country</strong> refers to: Colorado, United States
          </p>
        </li>
        <li>
          <p>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </p>
        </li>
        <li>
          <p>
            <strong>Third-party Social Media Service</strong> refers to any
            website or any social network website through which a User can log
            in or create an account to use the Service.
          </p>
        </li>
        <li>
          <p>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </p>
        </li>
        <li>
          <p>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </p>
        </li>
        <li>
          <p>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </p>
        </li>
      </ul>
      <h1>Collecting and Using Your Personal Data</h1>
      <h2>Types of Data Collected</h2>
      <h3>Personal Data</h3>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <ul>
        <li>
          <p>Email address</p>
        </li>
        <li>
          <p>First name and last name</p>
        </li>
        <li>
          <p>Phone number</p>
        </li>
        <li>
          <p>Address, State, Province, ZIP/Postal code, City</p>
        </li>
        <li>
          <p>Usage Data</p>
        </li>
      </ul>
      <h3>Usage Data</h3>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>
      <h3>Information from Third-Party Social Media Services</h3>
      <p>
        The Company allows You to create an account and log in to use the
        Service through the following Third-party Social Media Services:
      </p>
      <ul>
        <li>Google</li>
        <li>Facebook</li>
        <li>Twitter</li>
      </ul>
      <p>
        If You decide to register through or otherwise grant us access to a
        Third-Party Social Media Service, We may collect Personal data that is
        already associated with Your Third-Party Social Media Service's account,
        such as Your name, Your email address, Your activities or Your contact
        list associated with that account.
      </p>
      <p>
        You may also have the option of sharing additional information with the
        Company through Your Third-Party Social Media Service's account. If You
        choose to provide such information and Personal Data, during
        registration or otherwise, You are giving the Company permission to use,
        share, and store it in a manner consistent with this Privacy Policy.
      </p>
      <h3>Tracking Technologies and Cookies</h3>
      <p>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service.
      </p>
      <p>
        You can instruct Your browser to refuse all Cookies or to indicate when
        a Cookie is being sent. However, if You do not accept Cookies, You may
        not be able to use some parts of our Service.
      </p>
      <p>
        Cookies can be \"Persistent\" or \"Session\" Cookies. Persistent Cookies
        remain on your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close your web browser.
        Learn more about cookies:{" "}
        <a
          href='\"https://www.termsfeed.com/blog/cookies/\"'
          target='\"_blank\"'
        >
          All About Cookies
        </a>
        .
      </p>
      <p>
        We use both session and persistent Cookies for the purposes set out
        below:
      </p>
      <ul>
        <li>
          <p>
            <strong>Necessary / Essential Cookies</strong>
          </p>
          <p>Type: Session Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies Policy / Notice Acceptance Cookies</strong>
          </p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </p>
        </li>
        <li>
          <p>
            <strong>Functionality Cookies</strong>
          </p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </p>
        </li>
      </ul>
      <p>
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </p>
      <h2>Use of Your Personal Data</h2>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li>
          <strong>To provide and maintain our Service</strong>, including to
          monitor the usage of our Service.
        </li>
        <li>
          <strong>To manage Your Account:</strong> to manage Your registration
          as a user of the Service. The Personal Data You provide can give You
          access to different functionalities of the Service that are available
          to You as a registered user.
        </li>
        <li>
          <strong>For the performance of a contract:</strong> the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with Us
          through the Service.
        </li>
        <li>
          <strong>To contact You:</strong> To contact You by email, telephone
          calls, SMS, or other equivalent forms of electronic communication,
          such as a mobile application's push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or
          reasonable for their implementation.
        </li>
        <li>
          <strong>To provide You</strong> with news, special offers and general
          information about other goods, services and events which we offer that
          are similar to those that you have already purchased or enquired about
          unless You have opted not to receive such information.
        </li>
        <li>
          <strong>To manage Your requests:</strong> To attend and manage Your
          requests to Us.
        </li>
      </ul>
      <p>We may share your personal information in the following situations:</p>
      <ul>
        <li>
          <strong>With Service Providers:</strong> We may share Your personal
          information with Service Providers to monitor and analyze the use of
          our Service, to contact You.
        </li>
        <li>
          <strong>For Business transfers:</strong> We may share or transfer Your
          personal information in connection with, or during negotiations of,
          any merger, sale of Company assets, financing, or acquisition of all
          or a portion of our business to another company.
        </li>
        <li>
          <strong>With Affiliates:</strong> We may share Your information with
          Our affiliates, in which case we will require those affiliates to
          honor this Privacy Policy. Affiliates include Our parent company and
          any other subsidiaries, joint venture partners or other companies that
          We control or that are under common control with Us.
        </li>
        <li>
          <strong>With Business partners:</strong> We may share Your information
          with Our business partners to offer You certain products, services or
          promotions.
        </li>
        <li>
          <strong>With other users:</strong> when You share personal information
          or otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside. If You interact with other users or register through a
          Third-Party Social Media Service, Your contacts on the Third-Party
          Social Media Service may see Your name, profile, pictures and
          description of Your activity. Similarly, other users will be able to
          view descriptions of Your activity, communicate with You and view Your
          profile.
        </li>
      </ul>
      <h2>Retention of Your Personal Data</h2>
      <p>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </p>
      <p>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>
      <h2>Transfer of Your Personal Data</h2>
      <p>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>
      <h2>Disclosure of Your Personal Data</h2>
      <h3>Business Transactions</h3>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <h3>Law enforcement</h3>
      <p>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <h3>Other legal requirements</h3>
      <p>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </ul>
      <h2>Security of Your Personal Data</h2>
      <p>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <h1>Your California Privacy Rights (California's Shine the Light law)</h1>
      <p>
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes.
      </p>
      <p>
        If you'd like to request more information under the California Shine the
        Light law, and if you are a California resident, You can contact Us
        using the contact information provided below.
      </p>
      <h1>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </h1>
      <p>
        California Business and Professions Code section 22581 allow California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </p>
      <p>
        To request removal of such data, and if you are a California resident,
        You can contact Us using the contact information provided below, and
        include the email address associated with Your account.
      </p>
      <p>
        Be aware that Your request does not guarantee complete or comprehensive
        removal of content or information posted online and that the law may not
        permit or require removal in certain circumstances.
      </p>
      <h1>Links to Other Websites</h1>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h1>Changes to this Privacy Policy</h1>
      <p>
        We may update our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the \"Last updated\"
        date at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h1>Contact Us</h1>
      <p>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul>
        <li>By writing to us at hello@mytrinetwork.com</li>
      </ul>
    </div>
  );
}
