
import createRouter, { Router } from 'router5'
import browserPlugin from 'router5-plugin-browser'

const AppRoutes = {
    "HOME":"home",
    "MEMBERS":"members",
    "HELP":"help",
    "EDITPROFILE":"edit-profile",
    "ACCOUNT":"account-settings",
    "PAYMENT":"payment-settings",
    "ADMIN":"admin",
    "ACTIVATION":"activation?:email&:token&:failed&:success"
}

const basePath = "/dash/"

let AppRouter: Router;
function InitAppRouter() {
    const routes = [
        { name: AppRoutes.HOME, path: basePath+AppRoutes.HOME },
        { name: AppRoutes.MEMBERS, path: basePath+AppRoutes.MEMBERS},
        { name: AppRoutes.HELP, path: basePath+AppRoutes.HELP},
        { name: AppRoutes.EDITPROFILE, path: basePath+AppRoutes.EDITPROFILE},
        { name: AppRoutes.ACCOUNT, path: basePath+AppRoutes.ACCOUNT},
        { name: AppRoutes.PAYMENT, path: basePath+AppRoutes.PAYMENT},
        { name: AppRoutes.ADMIN, path:basePath+AppRoutes.ADMIN},
        { name: AppRoutes.ACTIVATION, path: basePath+AppRoutes.ACTIVATION}
    ]

    AppRouter = createRouter(routes, {
        "defaultRoute": AppRoutes.HOME
    });

    AppRouter.usePlugin(browserPlugin());
    AppRouter.start();
    return AppRouter;
}

export {InitAppRouter, AppRoutes};