import React, { useState, useEffect } from "react";
import {
  Header,
  Container,
  List,
  Modal,
  Button,
  Icon,
  Divider,
  Grid,
  Message,
} from "semantic-ui-react";
import { ActivationData } from "../../types";
import { useRoute, useRouter } from "react-router5";
import { staticRootURL } from "../../constants";

export default function Payment({
  activationData,
  updateStage,
}: {
  activationData: ActivationData;
  updateStage: (n: number, body?: string) => void;
}) {
  const f = useRouter().getState().params.failed;
  const [redirecting, setRedirecting] = useState(false);
  const [failed, setFailed] = useState(false);
  useEffect(() => {
    setFailed(f);
    updateStage(2);
  }, []);

  if (activationData.previousDues == 0) {
    return (
      <div>
        <Header as="h2" icon textAlign="center">
          <Header.Content className="terms-header">Payment</Header.Content>
        </Header>
        <Container textAlign="center">
          <p>Looks like you have no pending dues.</p>
          <p> Please proceed further.</p>
          <br />
          <br />
          <Button
            icon
            labelPosition="left"
            fluid
            onClick={() => {
              updateStage(3);
            }}
            size="large"
            primary
          >
            Proceed
            <Icon name="checkmark"></Icon>
          </Button>
        </Container>
      </div>
    );
  }

  return (
    <div>
       
      <Header as="h2" textAlign="center">
        <Header.Content className="terms-header">Payment</Header.Content>
      </Header>
      <Message style={{ display: failed ? "block" : "none" }}
          error
          attached
          header="Payment failure"
          content="Your payment attempt failed. You can try again, or email us at hello@mytrinetwork.com if you are stuck or not able to make a payment."
          size="small"
        />
      <Container textAlign="center" >
        <p style={{ display: failed ? "none" : "block" }}>You have pending dues with TRI.</p>
        <br />
        <Divider />
      </Container>
      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column>
            <Header block>
              <Header sub>Price</Header>
              <span>${activationData.previousDues / 100}</span>
              <Header sub>Due towards</Header>
              <span>Annual dues through June 2021</span>
              <Header sub>Total amount due</Header>
              <span>${activationData.previousDues / 100}</span>
            </Header>
          </Grid.Column>
          <Grid.Column verticalAlign="middle">
            <Button
              icon
              labelPosition="left"
              fluid
              loading={!activationData.stripeSession || redirecting}
              disabled={!activationData.stripeSession}
              onClick={() => {
                setRedirecting(true);
                window.location.replace(
                  staticRootURL +
                    "/stripe-checkout/index.html#" +
                    activationData.stripeSession
                );
              }}
              size="large"
              primary
            >
              Pay ${activationData.previousDues / 100} with card
              <Icon name="credit card"></Icon>
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Container>
        <Divider />
        <br />
        <Button
          icon
          labelPosition="left"
          fluid
          disabled
          onClick={() => {
            updateStage(3);
          }}
          size="large"
          primary
        >
          Proceed
          <Icon name="checkmark"></Icon>
        </Button>
      </Container>
    </div>
  );
}
