import React from "react";
import {
  Menu,
  Segment,
  Icon,
  Image,
  MenuItem,
  Dropdown,
} from "semantic-ui-react";
import { Router } from "router5";
import { AppRoutes } from "../../app-router";
import { useRoute } from "react-router5";
import "./topmenu.css";
import UserDetails from "../activation/user-details";

function TopMenu() {
  const AppRouter = useRoute().router;
  let activeItem = AppRouter.getState().name;
  console.log(activeItem, AppRoutes.HOME);

  return (
    <Segment >
      <Menu secondary stackable size="huge">
        <Menu.Item>
          <img src="../logo.png" alt="" />
          &nbsp; &nbsp; &nbsp;Templar Research Institute
        </Menu.Item>
        <Menu.Item
          link
          onClick={() => {
            AppRouter.navigate(AppRoutes.HOME);
          }}
          name="home"
          active={activeItem === "home"}
        >
          <Icon name="book"></Icon>Home
        </Menu.Item>
        <Menu.Item
          link
          name="Members"
          onClick={() => {
            AppRouter.navigate(AppRoutes.MEMBERS);
          }}
          active={activeItem === "members"}
        >
          <Icon name="user circle"></Icon> Members
        </Menu.Item>
        <Menu.Item link name="Matrix">
          <Icon name="terminal"></Icon> Matrix
        </Menu.Item>
        <Menu.Item link name="Shop">
          <Icon name="shop"></Icon>Shop
        </Menu.Item>
        <Menu.Item
          link
          name="Help"
          onClick={() => {
            AppRouter.navigate(AppRoutes.HELP);
          }}
        >
          <Icon name="help circle"></Icon>Support
        </Menu.Item>
        <Menu.Item
          link
          name="Administration"
          style={{"display":"none"}}
          onClick={() => {
            AppRouter.navigate(AppRoutes.ADMIN);
          }}
        >
          {" "}
          <Icon name="coffee"></Icon>
          Administration
        </Menu.Item>

        <Menu.Menu position="right">
          <Dropdown item text="My Account">
            <Dropdown.Menu>
              <Dropdown.Item
                icon="edit"
                text="Edit Profile"
                onClick={() => {
                  AppRouter.navigate(AppRoutes.EDITPROFILE);
                }}
              />
              <Dropdown.Item
                icon="settings"
                text="Account Settings"
                onClick={() => {
                  AppRouter.navigate(AppRoutes.ACCOUNT);
                }}
              />
              <Dropdown.Item
                icon="payment"
                text="Payment Options"
                onClick={() => {
                  AppRouter.navigate(AppRoutes.PAYMENT);
                }}
              />
              <Dropdown.Item icon="log out" text="Logout" />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    </Segment>
  );
}

export default TopMenu;
