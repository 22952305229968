import React from 'react';
import { Card, Icon, Placeholder, Segment, Search, Divider } from 'semantic-ui-react';

function MemberCard() {
    return (
        <Card link>
            <Placeholder>
                <Placeholder.Image square />
            </Placeholder>
            <Card.Content>
                <Card.Header>Matthew</Card.Header>
                <Card.Meta>
                    <span className='date'>Joined in 2020</span>
                </Card.Meta>
                <Card.Description>
                    User is a musician living in Nashville.
      </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <a>
                    <Icon name='user' />
                    User
                </a>
            </Card.Content>
        </Card>
    )
}


function Members() {
    return (
        <Segment>
            <Search/>
            <Divider/>
        <Card.Group doubling itemsPerRow={6} stackable>
            <MemberCard/>
            <MemberCard/>
            <MemberCard/>
            
        </Card.Group>
        </Segment>
    )
}

export default Members;