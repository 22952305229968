import React, { useState, useEffect } from "react";
import {
  Dimmer,
  Loader,
  Segment,
  Header,
  Icon,
  Modal,
  Button,
  Container,
  Transition,
} from "semantic-ui-react";

export default function Loading() {


  return (
      <Modal size="fullscreen" closeOnEscape={false} closeOnDimmerClick={false} defaultOpen basic dimmer="inverted">
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <h1>Welcome to TRI</h1> <br />
            <br />
            <p style={{ fontSize: "20px" }}> Please wait...</p>
          </div>
        </Modal.Content>
      </Modal>
  );
}
