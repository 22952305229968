import React, { useState, useEffect } from "react";
import {
  Header,
  Container,
  List,
  Modal,
  Button,
  Icon,
  Divider,
  Segment,
  Message,
  Form,
  Label,
  Input,
} from "semantic-ui-react";
import { useRouter, Link } from "react-router5";
import { ActivationData } from "../../types";
import { serverRootURL } from "../../constants";

export default function Accounts({
  activationData,
  updateStage,
}: {
  activationData: ActivationData;
  updateStage: (n: number, body?: string) => void;
}) {
  const [success, setSuccess] = useState(false);

  const [matrixPasswordError, setMatrixPasswordError] = useState(false);
  const [membersPasswordError, setMembersPasswordError] = useState(false);
  const [matrixUserError, setMatrixUserError] = useState(false);

  const [matrixPassword, setMatrixPassword] = useState("");
  const [matrixUser, setMatrixUser] = useState("");
  const [membersPassword, setMembersPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const s = useRouter().getState().params.success;

  useEffect(() => {
    if (s) {
      setSuccess(true);
    }
  }, []);

  function formSubmit() {
    let errors;

    if (matrixPassword.length < 8) {
      setMatrixPasswordError(true);
      errors = true;
    } else {
      setMatrixPasswordError(false);
    }

    if (membersPassword.length < 8) {
      setMembersPasswordError(true);
      errors = true;
    } else {
      setMembersPasswordError(false);
    }

    if (matrixUser.length < 4 || !/^[a-zA-Z0-9]*$/.test(matrixUser)) {
      setMatrixUserError(true);
      errors = true;
    } else {
      setMatrixUserError(false);
    }

    if (!errors) {
      setLoading(true);
      //set up accounts.

      let data = {
        passwordMain: membersPassword,
        passwordMatrix: matrixPassword,
        userMatrix: matrixUser,
      };

      let url = serverRootURL + "/activate" + "/createLogin";

      fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer "+localStorage.getItem("activationToken"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
          if(res.status==200){
              updateStage(4);
          } else {
              if(res.status==409){
                  setLoading(false);
                  setMatrixUserError(true);
              } else {
                  alert("an unknown error occured. Please check your internet or write to us at hello@mytrinetwork.com. Error code - "+res.status);
              }
          }
      });
    }
  }

  return (
    <div>
      <div style={{ display: success ? "block" : "none" }}>
        <Message
          success
          attached
          header="Payment Successful!"
          content="You have successfuly paid your membership dues. Time to set up your new accounts!"
          size="small"
        />
      </div>
      <Container>
        <Header as="h2" textAlign="center">
          <Header.Content className="terms-header">
            Set up your TRI accounts
          </Header.Content>
        </Header>
      </Container>
      <Segment.Group raised>
        <Segment as="h4">
          {" "}
          TRI Email account <Label color="teal">No setup required</Label>
        </Segment>
        <Segment>
          Your TRI Google Suite/email account will be ready in a few days, and
          login credentials would be emailed to you then. Mails to this address
          are being forwarded to your current email address till that happens.
        </Segment>
        <Segment.Group>
          <Segment>
            <Label basic>Access Website</Label>{" "}
            <a href="#" onClick={(e) => e.preventDefault()}>
              gmail.com
            </a>
          </Segment>
          <Segment textAlign="left">
            <Input
              fluid
              label="e-mail"
              placeholder="email-address"
              value={activationData.email}
            />
          </Segment>
        </Segment.Group>
        <Segment as="h4">
          {" "}
          TRI Members' Area account <Label color="teal">Set up password</Label>
        </Segment>
        <Segment>
          You can access your Members' Area account using your new email address
          at{" "}
          <a href="#" onClick={(e) => e.preventDefault()}>
            mytrinetwork.com
          </a>
          . Please choose your password.
        </Segment>
        <Segment.Group>
          <Segment>
            <Label basic>Access Website</Label>{" "}
            <a href="#" onClick={(e) => e.preventDefault()}>
              mytrinetwork.com
            </a>
          </Segment>
          <Segment textAlign="left">
            <Input
              fluid
              label="e-mail"
              placeholder="email-address"
              value={activationData.email}
            />
          </Segment>
          <Segment textAlign="left">
            <Label
              style={{
                display: membersPasswordError ? "inline-block" : "none",
              }}
              color="orange"
              pointing="below"
            >
              Password must be at least 8 characters long.{" "}
            </Label>
            <Input
              type="password"
              fluid
              label="password"
              placeholder="choose a password (at least 8 characters)"
              value={membersPassword}
              onInput={(e: any) => {
                setMembersPassword(e.target.value);
              }}
            />
          </Segment>
        </Segment.Group>
        <Segment as="h4">
          {" "}
          TRI Matrix account{" "}
          <Label color="teal">Set up username and password</Label>
        </Segment>
        <Segment>
          Set up your username and password for your Matrix account at{" "}
          <a onClick={(e) => e.preventDefault()} href="#">
            mytrinetwork.org
          </a>
          <br /> Please note that your username can <strong>never</strong> be
          changed. Be sure you are happy with the one you choose now.
          <br /> You will be able to login to your matrix account using your
          username and password.
        </Segment>
        <Segment.Group>
          <Segment>
            <Label basic>Access Website</Label>{" "}
            <a href="#" onClick={(e) => e.preventDefault()}>
              mytrinetwork.org
            </a>
          </Segment>
          <Segment textAlign="left">
            <Label
              style={{ display: matrixUserError ? "inline-block" : "none" }}
              color="orange"
              pointing="below"
            >
              That username is already taken or is invalid, try something else{" "}
            </Label>
            <Input
              fluid
              label="username"
              placeholder="Your desired username (only alphanumeric characters, and at least 4 characters)"
              value={matrixUser}
              onInput={(e: any) => {
                setMatrixUser(e.target.value);
              }}
            />
          </Segment>
          <Segment textAlign="left">
            <Label
              style={{ display: matrixPasswordError ? "inline-block" : "none" }}
              color="orange"
              pointing="below"
            >
              Password must be at least 8 characters long.
            </Label>
            <Input
              type="password"
              fluid
              label="password"
              placeholder="choose a password (at least 8 characters)"
              value={matrixPassword}
              onInput={(e: any) => {
                setMatrixPassword(e.target.value);
              }}
            />
          </Segment>
        </Segment.Group>
      </Segment.Group>

      <Segment raised>
        <Label basic>Tip</Label> It might be a good idea to note down these
        details somewhere handy before proceeding.
      </Segment>

      <Segment
        style={{
          display:
            matrixPasswordError || matrixUserError || membersPasswordError
              ? "block"
              : "none",
        }}
        color="orange"
        raised
        inverted
      >
        Oops ! There were some errors in your form. Please check your entries
        above.
      </Segment>

      <Button
        icon
        labelPosition="left"
        fluid
        onClick={() => {
          formSubmit();
        }}
        size="large"
        primary
        loading={loading}
      >
        Proceed
        <Icon name="angle right"></Icon>
      </Button>
    </div>
  );
}
