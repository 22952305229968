import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import TopMenu from "./topmenu";
import { UserData } from "../../types";
import { useRoute } from "react-router5";
import { AppRoutes } from "../../app-router";
import Home from "./home";
import Members from "./members";
import Admin from "./admin";
import Loading from "../loading";
import { logoutRedirectURL } from "../../constants";
import { Modal, Button } from "semantic-ui-react";

function getUserData(path: string): UserData {
  if (
    !localStorage.getItem("userData") ||
    typeof JSON.parse(localStorage.getItem("userData") as string) ===
      "undefined"
  ) {
    let newPath = path
      ? logoutRedirectURL + "?redirect=" + path
      : logoutRedirectURL;
    window.location.replace(newPath);
  }
  return JSON.parse(localStorage.getItem("userData") as string);
}

function Switcher({ userData }: { userData: UserData }) {
  let route = useRoute().route;
  switch (route.name) {
    case AppRoutes.HOME: {
      return <Home userData={userData} />;
      break;
    }
    case AppRoutes.MEMBERS: {
      return <Members />;
      break;
    }
    case AppRoutes.HELP: {
      break;
    }
    case AppRoutes.ADMIN: {
      return <Admin />;
      break;
    }
    case AppRoutes.EDITPROFILE: {
      break;
    }
    case AppRoutes.ACCOUNT: {
      break;
    }
    case AppRoutes.PAYMENT: {
      break;
    }
  }
  return <h1>{route.name}</h1>;
}

export default function MainApp() {
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(true);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    admin: false,
    activated: false,
    activationStage: 0,
    accountStanding: false,
    country: "",
    paymentPlan: "",
  });
  let path = useRoute().route.path;
  useEffect(() => {
    setUserData(getUserData(path));
    setLoaded(true);
  }, []);

  if (!loaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <div>
      <TopMenu />
      <Switcher userData={userData} />
      <Modal
        dimmer="blurring"
        closeOnDimmerClick={false}
        open={show}
        onClose={() => {
          setShow(!show);
        }}
      >
        <Modal.Header>Hey, you're early! </Modal.Header>
        <Modal.Content>
          <p>
            We are working on design and development of the Members' Area.{" "}
            <br /> It will be online soon. <br /> <br /> Thank you for visiting,
            and please do check back later!{" "}
          </p>
        </Modal.Content>
      </Modal>
    </div>
  );
}
