import React from "react";
import {
  Modal,
  Step,
  Icon,
  Segment,
  Header,
  Responsive,
  Container,
  Message,
  Button,
  Grid,
  List,
  Menu,
} from "semantic-ui-react";
import "./activate-user.css";
import Terms from "./terms";
import UserDetails from "./user-details";
import { ActivationData } from "../../types";
import Payment from "./payment";
import Accounts from "./accounts";
import Welcome from "./welcome";

export default function ActivateUser({
  activationData,
  updateStage,
}: {
  activationData: ActivationData;
  updateStage: (n: number) => void;
}) {
  let stage = activationData.activationStage;
  return (
    <Container style={{ paddingTop: "20px" }}>
      <Menu secondary stackable size="huge">
        <Menu.Item>
          <img src="../../logo.png" alt="" />
          &nbsp; &nbsp; &nbsp;Templar Research Institute
        </Menu.Item>
      </Menu>
      <Message
        info
        attached
        header="Welcome to TRI!"
        content="You need to complete the steps below in order to set up your new TRI account."
        size="small"
      />

      <Step.Group style={{ pointerEvents: "none" }} widths={5} size="small">
        <Responsive minWidth={stage == 0 ? "" : "768"}>
          <Step completed={stage > 0} active={stage == 0}>
            <Icon name="info circle" color={stage >= 0 ? "blue" : "grey"} />
            <Step.Content>
              <Step.Title>Terms and Privacy</Step.Title>
              <Responsive minWidth="992">
                <Step.Description>Review our terms</Step.Description>
              </Responsive>
            </Step.Content>
          </Step>
        </Responsive>
        <Responsive minWidth={stage == 1 ? "" : "768"}>
          <Step completed={stage > 1} active={stage == 1}>
            <Icon name="mail" color={stage >= 1 ? "blue" : "grey"} />
            <Step.Content>
              <Step.Title>Your Details</Step.Title>
              <Responsive minWidth="992">
                <Step.Description>Confirm your details</Step.Description>
              </Responsive>
            </Step.Content>
          </Step>
        </Responsive>

        <Responsive minWidth={stage == 2 ? "" : "768"}>
          <Step completed={stage > 2} active={stage == 2}>
            <Icon name="payment" color={stage >= 2 ? "blue" : "grey"} />
            <Step.Content>
              <Step.Title>Payment</Step.Title>
              <Responsive minWidth="992">
                <Step.Description>Pay your membership dues</Step.Description>
              </Responsive>
            </Step.Content>
          </Step>
        </Responsive>

        <Responsive minWidth={stage == 3 ? "" : "768"}>
          <Step completed={stage > 3} active={stage == 3}>
            <Icon name="user plus" color={stage >= 3 ? "blue" : "grey"} />
            <Step.Content>
              <Step.Title>Accounts</Step.Title>
              <Responsive minWidth="992">
                <Step.Description>Set up your accounts</Step.Description>
              </Responsive>
            </Step.Content>
          </Step>
        </Responsive>

        <Responsive minWidth={stage == 4 ? "" : "768"}>
          <Step disabled={stage != 4} completed={stage == 4}>
            <Icon name="info" color={stage >= 4 ? "blue" : "grey"} />
            <Step.Content>
              <Step.Title>All done</Step.Title>
            </Step.Content>
          </Step>
        </Responsive>
      </Step.Group>
      <Grid stackable columns={16}>
        <Grid.Column
          largeScreen={10}
          widescreen={8}
          computer={12}
          tablet={16}
          mobile={16}
        >
          <Segment placeholder size="large" attached>
            {ActivationSwitcher(activationData, updateStage)}
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

function ActivationSwitcher(
  activationData: ActivationData,
  updateStage: (stage: number) => void
) {
  let stage = activationData.activationStage;
  switch (stage) {
    case 0:
      return <Terms updateStage={updateStage} />;
    case 1:
      return (
        <UserDetails
          activationData={activationData}
          updateStage={updateStage}
        />
      );
    case 2:
      return (
        <Payment activationData={activationData}
        updateStage={updateStage}/>
      );
    case 3: 
      return (
        <Accounts activationData={activationData}
        updateStage={updateStage}/>
      );

    case 4:
      return (
        <Welcome/>
      );
  }

  return <div>{stage}</div>;
}
